import Vue from "vue";
import Vuex from "vuex";
import UserService from "./UserService";
import router from "../router/index.js";
import jwt_decode from "jwt-decode";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    page_loading: false,
    drawer: null,
    uri_settings: null,
    show_group_dialog: false,
    editing_group: null,
    access_token: null,
    refresh_token: null,
    userId: null,
    user: null,
    groups: null,
    group_settings: null,
    monitoring_groups: null,
    certificate_settings: null,
    overlay: false,
    overlay_text: null,
    overlay_style: "loading",
    instance: {
      id: 0,
      name: "Not Authenticated",
      api_url: null,
      public_api_url: null,
      socket_url: "ws://localhost:8000/ws/chat/live/",
      private: false
    },
    socket: {
      isConnected: false,
      message: "",
      reconnectError: false
    },
    instance_info: null,
    public_instance_info: null
  },
  mutations: {
    SOCKET_ONOPEN(state, event) {
      Vue.prototype.$socket = event.currentTarget;
      state.socket.isConnected = true;
    },
    SOCKET_ONCLOSE(state) {
      state.socket.isConnected = false;
    },
    SOCKET_ONERROR(state, event) {
      console.error(state, event);
    },
    // default handler called for all methods
    SOCKET_ONMESSAGE(state, message) {
      state.socket.message = message;
    },
    // mutations for reconnect methods
    SOCKET_RECONNECT(state, count) {
      console.info(state, count);
    },
    SOCKET_RECONNECT_ERROR(state) {
      state.socket.reconnectError = true;
    },
    authUser(state, userData) {
      state.access_token = userData.access_token;
      state.refresh_token = userData.refresh_token;
      state.user_id = userData.user_id;
      state.instance = userData.instance;
    },
    clearAuth(state) {
      state.refresh_token = null;
      state.access_token = null;
      state.userId = null;
    },
    showCertificate() {},
    editMonitoringGroup(state, group) {
      state.show_group_dialog = true;
      state.editing_group = group;
    }
  },
  actions: {
    AutoLogin({ commit, dispatch }) {
      const access_token = localStorage.getItem("access_token");
      const refresh_token = localStorage.getItem("refresh_token");
      const user_id = localStorage.getItem("sub");
      const instance = localStorage.getItem("instance");

      if (!access_token || !instance) {
        router.push("/");
        return;
      }
      commit("authUser", {
        access_token: access_token,
        refresh_token: refresh_token,
        user_id: user_id,
        instance: JSON.parse(instance)
      });
      if (this.getters.ifAuthenticated) {
        dispatch("loadUserSettings");
        dispatch("loadInstanceInfo");
      }
    },
    signup() {},
    loadInstanceInfo() {
      let self = this;
      UserService.getLocalApi("info").then(function(response) {
        self.state.instance_info = response.data;
      });
      if (self.state.instance.public_api_url) {
        UserService.getRemoteApi("info").then(function(response) {
          self.state.public_instance_info = response.data;
        });
      }
    },
    loadUserSettings(silent = false) {
      let self = this;
      if (!silent) {
        self.state.overlay = true;
      }
      self.state.overlay_style = "loading";
      self.state.overlay_text = "fetching groups...";
      UserService.getLocalApi("monitoring-groups")
        .then(function(response) {
          self.state.groups = response.data.results;
          self.state.overlay_text = "loading group settings...";
          UserService.getLocalApi("monitoring-group-settings")
            .then(function(response) {
              self.state.group_settings = response.data.results;

              // all groups and their settings if they exist
              const map = new Map();
              self.state.groups.forEach(item =>
                map.set(item.id, { group: item, settings: [] })
              );
              self.state.group_settings.forEach(item =>
                map.set(item.monitoring_group, {
                  ...map.get(item.monitoring_group),
                  settings: item
                })
              );
              self.state.monitoring_groups = Array.from(map.values());

              self.state.overlay_text = "personalizing...";

              UserService.getLocalApi("user-certificate-settings/?size=10000")
                .then(function(response) {
                  self.state.certificate_settings = response.data.results;

                  self.state.overlay_text = "url settings...";
                  UserService.getLocalApi("user-uri-settings/?size=10000")
                    .then(function(response) {
                      self.state.uri_settings = response.data.results;
                      self.state.overlay = false;
                    })
                    .catch(function(error) {
                      self.state.overlay_style = "error";
                      self.state.overlay_text = error;
                      console.log(error);
                    });
                })
                .catch(function(error) {
                  self.state.overlay_style = "error";
                  self.state.overlay_text = error;
                  console.log(error);
                });
            })
            .catch(function(error) {
              self.state.overlay_style = "error";
              self.state.overlay_text = error;
              console.log(error);
            });
        })
        .catch(function(error) {
          self.state.overlay_style = "error";
          self.state.overlay_text = error;
          console.log(error);
        });
    },
    login({ commit, dispatch }, authData) {
      let self = this;
      this.state.instance = authData.instance;
      this.state.overlay = true;
      this.state.overlay_text = "authenticating...";
      this.state.overlay_style = "loading";

      UserService.postLocalApi("token", {
        username: authData.email,
        password: authData.password
      })
        .then(res => {
          commit("authUser", {
            access_token: res.data.access,
            refresh_token: res.data.refresh,
            userId: res.data.localId,
            instance: this.state.instance
          });
          localStorage.setItem("access_token", res.data.access);
          localStorage.setItem("refresh_token", res.data.refresh);
          localStorage.setItem("userId", res.data.localId);
          localStorage.setItem("instance", JSON.stringify(this.state.instance));
          dispatch("loadUserSettings");
          dispatch("loadInstanceInfo");
        })
        .catch(error => {
          self.state.overlay_style = "error";
          if (error.response) {
            self.state.overlay_text = error;
          } else {
            self.state.overlay_text = "Unable to connect";
          }
          console.log(error);
        });
    },
    refreshToken({ commit, dispatch }) {
      let self = this;
      UserService.postLocalApi("token/refresh/", {
        refresh: this.state.refresh_token
      })
        .then(res => {
          //alert("REFRESHED TOKEN! WOO");
          commit("authUser", {
            access_token: res.data.access,
            refresh_token: self.state.refresh_token,
            userId: self.state.userId,
            instance: self.state.instance
          });
          localStorage.setItem("access_token", res.data.access);
        })
        .catch(error => {
          //router.push("/about");
          console.log(error);
          self.state.overlay = false;
          dispatch("logout");
        });
    },
    logout({ commit }) {
      commit("clearAuth");
      localStorage.removeItem("access_token");
      localStorage.removeItem("refresh_token");
      localStorage.removeItem("userId");
      localStorage.removeItem("instance");
      this.state.certificate_settings = null;
      this.state.monitoring_groups = null;
      this.state.groups = null;
      this.state.group_settings = null;
      this.state.uri_settings = null;
      router.push("/");
    },
    show_certificate_dialog({ commit }, cert) {
      commit("showCertificate", cert);
    },
    edit_monitoring_group({ commit }, group) {
      commit("editMonitoringGroup", group);
    }
  },
  modules: {},
  getters: {
    instance_info(state) {
      return state.instance_info;
    },
    public_instance_info(state) {
      return state.public_instance_info;
    },
    drawer(state) {
      return state.drawer;
    },
    page_loading(state) {
      return state.page_loading;
    },
    token_json(state) {
      return jwt_decode(state.access_token);
    },
    instance(state) {
      return state.instance;
    },
    user(state) {
      return state.user;
    },
    ifAuthenticated(state) {
      try {
        //jwt_decode(state.access_token);
        const { exp } = jwt_decode(state.access_token);
        if (Date.now() >= exp * 1000) {
          return false;
        }
      } catch (err) {
        return false;
      }
      return true;
    },
    monitoring_groups(state) {
      return state.monitoring_groups;
    },
    monitored_groups(state) {
      // groups the user has settings for (main table)
      if (state.monitoring_groups) {
        return state.monitoring_groups.filter(function(value) {
          return value.settings.length !== 0;
        });
      } else {
        return [];
      }
    },
    unmonitored_groups(state) {
      // groups the user doesn't monitor (no settings)
      if (state.monitoring_groups) {
        return state.computed_groups.filter(function(value) {
          return value.settings.length === 0 && value.group.group_type === 3;
        });
      } else {
        return [];
      }
    },
    certificate_settings(state) {
      return state.certificate_settings;
    },
    show_group_dialog(state) {
      return state.show_group_dialog;
    },
    editing_group(state) {
      return state.editing_group;
    },
    monitored_uris(state) {
      return state.uri_settings;
    }
  }
});
