<template>
  <v-navigation-drawer
    app
    dense
    :expand-on-hover="$vuetify.breakpoint.smAndUp"
    v-model="drawer"
    :permanent="$vuetify.breakpoint.smAndUp"
    style="overflow-y:hidden;"
  >
    <v-list-item
      class="px-2"
      style="height:40px;background-image:linear-gradient(120deg,#053768,#7192B0 );"
    >
      <v-list-item-avatar>
        <a style="text-decoration:none" href="/" aria-label="Home"
          ><v-icon color="white">mdi-home</v-icon></a
        >
      </v-list-item-avatar>

      <v-list-item-title>
        <a
          href="/"
          style="padding:0;margin:0;padding-right:5px;text-decoration:none;"
        >
          <span class="logo-lg" style="color:white"
            ><b style="color:white">Trust</b>Monitor</span
          >
        </a>
      </v-list-item-title>
    </v-list-item>

    <v-divider></v-divider>

    <v-list nav dense>
      <v-list-item link to="/">
        <v-list-item-icon>
          <v-icon color="primary">mdi-monitor-dashboard</v-icon>
        </v-list-item-icon>
        <v-list-item-title v-if="auth">Dashboard</v-list-item-title>
        <v-list-item-title v-else>Log In</v-list-item-title>
      </v-list-item>

      <v-list-item link to="/trustexplorer">
        <v-list-item-icon>
          <v-icon color="primary">mdi-graphql</v-icon>
        </v-list-item-icon>
        <v-list-item-title>TrustExplorer</v-list-item-title>
      </v-list-item>

      <v-list-item link to="/reporting/availability/">
        <v-list-item-icon>
          <v-icon color="primary">mdi-chart-bar</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Reporting</v-list-item-title>
      </v-list-item>

      <v-list-item link to="/search/">
        <v-list-item-icon>
          <v-icon color="primary">mdi-magnify</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Advanced Search</v-list-item-title>
      </v-list-item>

      <v-list-item link disabled to="/users/">
        <v-list-item-icon>
          <v-icon color="primary">mdi-account-multiple</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Manage Users</v-list-item-title>
      </v-list-item>

      <v-list-item link href="/compare/" disabled>
        <v-list-item-icon>
          <v-icon color="primary">mdi-file-compare</v-icon>
          <v-badge
            v-show="compare_count > 0"
            :content="compare_count"
            color="secondary"
            overlap
          >
          </v-badge>
        </v-list-item-icon>
        <v-list-item-title>Certificate Comparison</v-list-item-title>
      </v-list-item>

      <v-list-item link disabled>
        <v-list-item-icon>
          <v-icon color="primary">mdi-graph-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-title>FPKI</v-list-item-title>
      </v-list-item>

      <v-list-item link href="/dns/" disabled>
        <v-list-item-icon>
          <v-icon color="primary">mdi-lan</v-icon>
        </v-list-item-icon>
        <v-list-item-title>DNS Monitoring</v-list-item-title>
      </v-list-item>

      <v-list-item link disabled>
        <v-list-item-icon>
          <v-icon color="primary">mdi-dns</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Certificate Transparency</v-list-item-title>
      </v-list-item>

      <v-list-item link to="/admin/">
        <v-list-item-icon>
          <v-icon color="primary">mdi-account-tie</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Administration</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: "Menu",
  data: function() {
    return {
      compare_count: 0
    };
  },
  computed: {
    auth() {
      return this.$store.getters.ifAuthenticated;
    },
    drawer: {
      get() {
        return this.$store.state.drawer;
      },
      set(newValue) {
        this.$store.state.drawer = newValue;
      }
    }
  }
};
</script>

<style scoped></style>
