import axios from "axios";
import authHeader from "./AuthHeader";
import store from "./index";

const content_types = {
  cer: "application/pkix-cert",
  crl: "application/pkix-crl",
  pem: "application/x-pem-file",
  p7b: "application/x-pkcs7-certificates",
  p7c: "application/x-pkcs7-certreqresp"
};

class UserService {
  refreshToken() {
    if (!store.getters.ifAuthenticated) {
      store.dispatch("refreshToken");
    }
  }

  downloadLocalFile(path, name, extension) {
    this.refreshToken();
    if (path[path.length - 1] !== "/" && path.indexOf("?") === -1) {
      path += "/";
    }
    return axios
      .get(store.getters.instance.api_url + path, {
        headers: authHeader(true),
        responseType: "blob"
      })
      .then(response => {
        const blob = new Blob([response.data], {
          type: "application/" + content_types[extension]
        });
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = name + "." + extension;
        link.click();
        URL.revokeObjectURL(link.href);
      });
  }

  getLocalApi(path) {
    this.refreshToken();
    if (path[path.length - 1] !== "/" && path.indexOf("?") === -1) {
      path += "/";
    }
    return axios.get(store.getters.instance.api_url + path, {
      headers: authHeader(true)
    });
  }

  patchLocalApi(path, data) {
    this.refreshToken();
    if (path[path.length - 1] !== "/") {
      path += "/";
    }
    return axios.patch(store.getters.instance.api_url + path, data, {
      headers: authHeader(true)
    });
  }

  deleteLocalApi(path) {
    this.refreshToken();
    if (path[path.length - 1] !== "/" && path.indexOf("?") === -1) {
      path += "/";
    }
    return axios.delete(store.getters.instance.api_url + path, {
      headers: authHeader(true)
    });
  }

  postLocalApi(path, data, headers = {}) {
    if (path[path.length - 1] !== "/") {
      path += "/";
    }
    if (path !== "token/" && path !== "token/refresh/") {
      this.refreshToken();
    }
    headers["headers"] = authHeader(true);
    return axios.post(store.getters.instance.api_url + path, data, headers);
  }

  putLocalApi(path, data) {
    this.refreshToken();
    if (path[path.length - 1] !== "/") {
      path += "/";
    }
    return axios.put(store.getters.instance.api_url + path, data, {
      headers: authHeader(true)
    });
  }

  getRemoteApi(path) {
    this.refreshToken();
    return axios.get(store.getters.instance.public_api_url + path, {
      headers: authHeader(true)
    });
  }

  postRemoteApi(path, data, headers = {}) {
    if (path[path.length - 1] !== "/" && path.indexOf("?") === -1) {
      path += "/";
    }
    if (path !== "token/" && path !== "token/refresh/") {
      this.refreshToken();
    }
    headers["headers"] = authHeader(true);
    return axios.post(
      store.getters.instance.public_api_url + path,
      data,
      headers
    );
  }
}

export default new UserService();
