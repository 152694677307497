<template>
  <v-app>
    <v-overlay :value="overlay" z-index="9002" opacity="0.7">
      <template v-if="overlay_style === 'loading'">
        <svg
          viewBox="-2 -5 90 46"
          width="100"
          height="50"
          style="margin-top:15px;width:100%"
          v-if="!goDark"
        >
          <path
            class="path3"
            fill="#e4e4e4"
            stroke="rgba(5, 55, 104,.6)"
            stroke-width="2"
            d="M 33.6 8.8 L 22.35 8.8 L 22.35 34.4 L 11.3 34.4 L 11.3 8.8 L 0 8.8 L 0 0 L 33.6 0 L 33.6 8.8 Z"
          />
          <path
            class="path3"
            fill="#e4e4e4"
            stroke="rgba(113, 146, 176,.6)"
            stroke-width="2"
            d="M 79.15 34.4 L 68.2 34.4 L 68.2 22 Q 68.2 19.7 68.375 17.275 A 121.746 121.746 0 0 1 68.51 15.565 Q 68.567 14.898 68.627 14.307 A 57.598 57.598 0 0 1 68.75 13.2 Q 68.944 11.6 68.997 11.128 A 27.416 27.416 0 0 0 69 11.1 L 68.8 11.1 L 62.45 34.4 L 53.8 34.4 L 47.4 11.15 L 47.2 11.15 A 17.772 17.772 0 0 0 47.225 11.36 Q 47.292 11.905 47.475 13.225 A 47.654 47.654 0 0 1 47.586 14.087 Q 47.747 15.435 47.895 17.217 A 129.35 129.35 0 0 1 47.9 17.275 A 57.649 57.649 0 0 1 48.1 21.856 A 53.066 53.066 0 0 1 48.1 22 L 48.1 34.4 L 37.95 34.4 L 37.95 0 L 53.55 0 L 58.75 19.85 L 58.95 19.85 L 64.1 0 L 79.15 0 L 79.15 34.4 Z"
          />
        </svg>
        <v-divider class="mb-2"></v-divider>
        <div style="width:100%;text-align:center;">
          <span style="font-size:.85em;">{{ overlay_text }}</span>
        </div>
      </template>
      <template v-else-if="overlay_style === 'error'">
        <svg
          viewBox="-2 -5 90 46"
          width="100"
          height="50"
          style="margin-top:15px;width:100%"
        >
          <path
            fill="#ddd"
            stroke="rgba(5, 55, 104,.6)"
            stroke-width="2"
            d="M 33.6 8.8 L 22.35 8.8 L 22.35 34.4 L 11.3 34.4 L 11.3 8.8 L 0 8.8 L 0 0 L 33.6 0 L 33.6 8.8 Z"
          />
          <path
            fill="#ddd"
            stroke="rgba(5, 146, 176,.6)"
            stroke-width="2"
            d="M 79.15 34.4 L 68.2 34.4 L 68.2 22 Q 68.2 19.7 68.375 17.275 A 121.746 121.746 0 0 1 68.51 15.565 Q 68.567 14.898 68.627 14.307 A 57.598 57.598 0 0 1 68.75 13.2 Q 68.944 11.6 68.997 11.128 A 27.416 27.416 0 0 0 69 11.1 L 68.8 11.1 L 62.45 34.4 L 53.8 34.4 L 47.4 11.15 L 47.2 11.15 A 17.772 17.772 0 0 0 47.225 11.36 Q 47.292 11.905 47.475 13.225 A 47.654 47.654 0 0 1 47.586 14.087 Q 47.747 15.435 47.895 17.217 A 129.35 129.35 0 0 1 47.9 17.275 A 57.649 57.649 0 0 1 48.1 21.856 A 53.066 53.066 0 0 1 48.1 22 L 48.1 34.4 L 37.95 34.4 L 37.95 0 L 53.55 0 L 58.75 19.85 L 58.95 19.85 L 64.1 0 L 79.15 0 L 79.15 34.4 Z"
          />
        </svg>

        <v-divider class="mb-2"></v-divider>
        <div style="width:100%;text-align:center;">
          <span style="font-size:.85em;color: red;font-weight:bold;">
            {{ overlay_text }}
            <br />
            <v-btn @click="overlay = false" color="primary" class="mt-2"
              >Try Again</v-btn
            >
          </span>
        </div>
      </template>
    </v-overlay>

    <v-overlay :value="overlay" z-index="9001" opacity=".0">
      <v-card style="width:1200px;height:1200px;background:transparent;" flat>
        <vue-particles
          color="#053768"
          :particleOpacity="0.8"
          :particlesNumber="70"
          shapeType="circle"
          :particleSize="4"
          linesColor="#7192B0"
          :linesWidth="1"
          :lineLinked="true"
          :lineOpacity="0.7"
          :linesDistance="130"
          :moveSpeed="3"
          :hoverEffect="true"
          hoverMode="grab"
          :clickEffect="true"
          clickMode="push"
          style="height:1200px"
          ><v-card>hello</v-card>
        </vue-particles>
      </v-card>
    </v-overlay>

    <MonitoringGroupConfigurationDialog />

    <Header />
    <Menu />

    <v-main class="pb-8">
      <v-container fluid style="max-width:1600px;" class="pa-0 ml-0 ">
        <router-view></router-view>
      </v-container>
    </v-main>
    <v-snackbar
      bottom
      right
      :value="updateExists"
      :timeout="-1"
      color="primary"
    >
      An update is available
      <v-btn text @click="refreshApp">
        Update
      </v-btn>
    </v-snackbar>

    <v-footer padless fixed style="border-top:1px solid #ddd;z-index:9100;">
      <v-card class="flex" flat tile>
        <v-card-text class="py-1 text-center">
          {{ new Date().getFullYear() }} — <strong>CertiPath, Inc.</strong>

          <v-divider vertical class="ma-2" />
          <template v-if="this.$store.state.socket.isConnected">
            <status-indicator positive class="mr-2 ml-4" />
            <span style="font-size:.8em;">WebSocket Connected</span>
          </template>

          <status-indicator v-else negative class="mr-2 ml-4" />
        </v-card-text>
      </v-card>
    </v-footer>
  </v-app>
</template>

<script>
import Header from "./components/Header";
import { StatusIndicator } from "vue-status-indicator";
//import axios from "axios";
import MonitoringGroupConfigurationDialog from "./components/groups/ConfigurationDialog";
import Menu from "./components/Menu";
import update from "./components/mixins/update";

if (location.protocol !== "https:" && location.host !== "localhost:8080") {
  location.protocol = "https:";
}

export default {
  name: "App",

  components: {
    Header,
    MonitoringGroupConfigurationDialog,
    Menu,
    StatusIndicator
  },

  data: () => ({
    main_loading: true,
    compare_count: 1,
    error_snackbar: false,
    error_snackbar_message: "Request Failed",
    success_snackbar: false,
    success_snackbar_message: "Success",
    main_snackbar: false,
    private_instance: false,
    loading: true,
    next_tile_url: null,
    logged_in: false,
    goDark: false,
    main_select: null,
    main_search: null,
    main_items: [],
    main_dialog: false,
    notifications: false,
    main_count: null,
    sound: true,
    widgets: false
  }),
  mixins: [update],
  computed: {
    overlay: {
      get() {
        //return true;
        return this.$store.state.overlay;
      },
      set(newValue) {
        this.$store.state.overlay = newValue;
      }
    },
    overlay_text() {
      return this.$store.state.overlay_text;
    },
    overlay_style() {
      return this.$store.state.overlay_style;
    },
    group_dialog() {
      return this.$store.state.show_group_dialog;
    },
    editing_group() {
      return this.$store.state.editing_group;
    },
    auth() {
      return this.$store.getters.ifAuthenticated;
    }
  },
  methods: {},
  created() {
    this.$store.dispatch("AutoLogin");

    /*
    let self = this;
    axios.interceptors.response.use(
      function(response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response;
      },
      function(error) {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        let err = error.response;

        if (
          err &&
          err.status === 401 &&
          err.config &&
          !err.config.__isRetryRequest
        ) {
          self.$store.dispatch("refreshToken");
        }
        return Promise.reject(error);
      }
    );
     */
  }
};
</script>
