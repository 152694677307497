import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "./registerServiceWorker";
import VueParticles from "vue-particles";

Vue.config.productionTip = false;
window.axios = require("axios");

Vue.use(VueParticles);

Vue.filter("str_limit", function(value, size) {
  if (!value) return "";
  value = value.toString();

  if (value.length <= size) {
    return value;
  }
  return value.substr(0, size) + "...";
});

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");
