export default function authHeader(local = true) {
  let token;
  if (local) {
    token = localStorage.getItem("access_token");
  } else {
    token = null;
  }

  if (token) {
    return { Authorization: "Bearer " + token };
  } else {
    return {};
  }
}
