import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";
Vue.use(VueRouter);

const routeOptions = [
  { path: "/", name: "Home" },
  { path: "/search", name: "Search" },
  { path: "/groups", name: "MonitoringGroups", meta: { requiresAuth: true } },
  {
    path: "/admin",
    name: "Admin",
    meta: { requiresAuth: true, requiresAdmin: false }
  },
  {
    path: "/settings/applications/",
    name: "PortalApplications",
    meta: { requiresAuth: true }
  },
  { path: "/cert/:id", name: "Certificate" },
  { path: "/crl/:id", name: "Crl" },
  { path: "/url/:id", name: "Url" },
  { path: "/trustexplorer/", name: "TrustExplorer" },
  { path: "/reporting/availability", name: "reports/Availability" },
  { path: "*", name: "PageNotFound" }
];

const routes = routeOptions.map(route => {
  return {
    ...route,
    component: () =>
      import(/* webpackChunkName: "[request]" */ `../views/${route.name}.vue`)
  };
});

const router = new VueRouter({
  mode: "history",
  routes
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.getters.ifAuthenticated) {
      next({
        path: "/",
        params: { nextUrl: to.fullPath }
      });
    } else {
      let user = JSON.parse(localStorage.getItem("user"));
      if (to.matched.some(record => record.meta.requiresAdmin)) {
        if (user.is_admin === 1) {
          next();
        } else {
          next({ name: "userboard" });
        }
      } else {
        next();
      }
    }
  } else if (to.matched.some(record => record.meta.guest)) {
    if (localStorage.getItem("jwt") == null) {
      next();
    } else {
      next({ name: "userboard" });
    }
  } else {
    next();
  }
});

export default router;
