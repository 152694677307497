<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
    v-if="dialog"
  >
    <v-card>
      <v-toolbar dark color="primary" style="border-radius:0;">
        <v-btn icon dark @click="close_dialog">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>{{ group.group.name }}</v-toolbar-title>
      </v-toolbar>
      <v-list three-line subheader>
        <v-subheader>General</v-subheader>
        <v-list-item dense>
          <v-list-item-avatar tile>
            <v-btn :color="group.settings.color"></v-btn>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>Group Color</v-list-item-title>
            <v-list-item-subtitle>
              Change the display color for this group
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-divider></v-divider>
      <v-list three-line subheader>
        <v-subheader>Certificates</v-subheader>
        <v-list-item>
          <v-list-item-action>
            <v-checkbox v-model="notifications"></v-checkbox>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Notifications</v-list-item-title>
            <v-list-item-subtitle
              >Notify me about updates to apps or games that I
              downloaded</v-list-item-subtitle
            >
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-action>
            <v-checkbox v-model="sound"></v-checkbox>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Sound</v-list-item-title>
            <v-list-item-subtitle
              >Auto-update apps at any time. Data charges may
              apply</v-list-item-subtitle
            >
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-action>
            <v-checkbox v-model="widgets"></v-checkbox>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Auto-add widgets</v-list-item-title>
            <v-list-item-subtitle
              >Automatically add home screen widgets</v-list-item-subtitle
            >
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "MonitoringGroupConfigurationDialog",
  data() {
    return {
      notifications: false,
      sound: true,
      widgets: false
    };
  },
  computed: {
    dialog: function() {
      return this.$store.getters.show_group_dialog;
    },
    group: function() {
      return this.$store.getters.editing_group;
    }
  },
  methods: {
    close_dialog: function() {
      this.$store.state.show_group_dialog = false;
    }
  }
};
</script>

<style scoped></style>
