import Vue from "vue";
import Vuetify from "vuetify";
//import VueNativeSock from "vue-native-websocket";
//import store from "../store";

Vue.use(Vuetify);
Vue.use(require("vue-moment"));
Vue.use(require("vue-chartist"));
/*
Vue.use(VueNativeSock, store.state.instance.socket_url, {
  store: store,
  format: "json",
  reconnection: false, // (Boolean) whether to reconnect automatically (false)
  reconnectionAttempts: 1, // (Number) number of reconnection attempts before giving up (Infinity),
  reconnectionDelay: 1000 // (Number) how long to initially wait before attempting a new (1000) });
});

 */

var amDark = false;
const opts = {
  theme: {
    dark: amDark,
    themes: {
      light: {
        primary: "#7192B0",
        secondary: "#053768"
      },
      dark: {
        primary: "#7192B0",
        secondary: "#053768"
      }
    }
  }
};
export default new Vuetify(opts);
